<template>
    <div>
        <v-dialog v-model="dialog"
                  dark
                  scrollable
                  persistent
                  max-width="750">
            <v-card>
                <v-card-title class="text-h5">
                    My Orders
                </v-card-title>

                <v-card-text>
                    <input autofocus style="visibility:hidden; width:1px;"> <!--Hack to prevent buttons from being auto-focused-->

                        <v-data-table :headers="receiptFields"
                                      :items="receipts"
                                      :hide-default-footer="true"
                                      :disable-pagination="true"
                                      fixed-header
                                      class="elevation-5 mb-5 secondary"
                                      dark>
                        </v-data-table>

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-4 float-left" color="red darken-1"
                           text
                           @click="closeDialog()">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

    import { orderService } from '../services/order-service';
    import { notificationHooks } from '../shared/vue-mixin';

    export default {
        name: 'ReceiptsForm',

        /* COMPONENT DATA  */
        data() {
            return {
                dialog : false,
                receipts: [],
                receiptFields: [
                    { value: 'productName', text: "Item", sortable: false, class: 'centeredCell' },
                    { value: 'orderDate', text: "Order Date", sortable: false, class: 'centeredCell' },
                    { value: 'confirmationNumber', text: "Confirmation #", sortable: false, class: 'centeredCell' },
                    { value: 'price', text: "Price", sortable: false, class: 'centeredCell' },
                    { value: 'expirationDate', text: "Expiration Date", sortable: false, class: 'centeredCell' },
                    { value: 'status', text: "Status", sortable: false, class: 'centeredCell' },
                ],
            };
        },

        /* MIXINS */
        mixins: [notificationHooks],

        /* COMPONENT METHODS  */
        methods: {

            /* PUBLIC METHODS  */
            async ShowModal() {
                this.dialog = true;
                var ordersResponse = await orderService.getMyOrders();

                if (ordersResponse.success) {
                    this.receipts = ordersResponse.value;
                }
                else {
                    var error = ordersResponse.error.replace(/['"]+/g, '');
                    this.showErrorMessage("CryptoTaxSpace", error);
                }
            },

            /* PRIVATE METHODS */
            closeDialog() {
                this.dialog = false;
            },
        },
    };
</script>

<style scoped>

    /deep/ .centeredCell {
        vertical-align: middle !important;
    }

    /deep/ .alignedCell {
        vertical-align: middle !important;
        text-align: center !important;
    }

</style>