import { API } from '../shared/config';
import { HTTP_GET  } from '../shared/constants';
import { serviceHelper } from '../shared/service-helper';

const getMyOrders = async function () {
    try {
        const response = await serviceHelper.callApi(`${API}/GetMyOrders`, HTTP_GET);
        return response;
    } catch (error) {
        return null;
    }
}

export const orderService = {
    getMyOrders
};